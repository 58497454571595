import GraphQLErrorList from "../components/graphql-error-list";
import Layout from "../containers/Layout";
import QInfoPage from "../queries/QInfoPage";
import { default as InfoPageComponent } from "../components/InfoPage";
import PageHelmet from "../components/PageHelmet";
import { SanityInfoPage, SanitySiteSettings } from "../../graphql-types";
interface InfoPageProps {
  site: SanitySiteSettings;
  infoPage: SanityInfoPage;
}
const InfoPage = (props) => {
  const { errors } = props;

  if (errors) {
    return (
      <Layout>
        <GraphQLErrorList errors={errors} />
      </Layout>
    );
  }

  const renderInfoPage = ({ site, infoPage }: InfoPageProps) => {
    const {
      donationUrl,
      ticketsUrl,
      streamUrl,
      streamathonLabel,
      streamathonDate,
      lanLabel,
      lanDate,
    } = site;

    return (
      <Layout>
        <PageHelmet title="Info" />
        <InfoPageComponent
          {...infoPage}
          globalUrls={{ donationUrl, ticketsUrl, streamUrl }}
          streamathonLabel={streamathonLabel}
          streamathonDate={streamathonDate}
          lanLabel={lanLabel}
          lanDate={lanDate}
        />
      </Layout>
    );
  };

  return <QInfoPage>{renderInfoPage}</QInfoPage>;
};

export default InfoPage;

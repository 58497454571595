import { StaticQuery, graphql } from "gatsby";

export default ({ children }) => (
  <StaticQuery
    query={query}
    render={({ site, infoPage }) => {
      return children({
        site,
        infoPage,
      });
    }}
  />
);
const query = graphql`
  query InfoPage {
    site: sanitySiteSettings(_id: { regex: "/(drafts.|)siteSettings/" }) {
      donationUrl
      ticketsUrl
      streamUrl
      streamathonLabel
      streamathonDate
      lanLabel
      lanDate
    }
    infoPage: sanityInfoPage {
      _rawLeadCopy
      graphicTitle
      _rawGraphicStreamItems
      _rawGraphicLanItems
      _rawBrandVisual(resolveReferences: { maxDepth: 10 })
      _rawEventDetails
      _rawImageLockup
      _rawTicketsCopy
      ticketsCards {
        _rawDetails
        color
        price
        _rawPricingInfo
        title
      }
      ticketsBuyCtaLabel
      primoTicketHidden
      ticketsCardPrimo {
        title
        subtitle
        price
        _rawDetailsTwo
        _rawDetailsOne
      }
      ticketsCardSpectator {
        _rawDetails
        color
        price
        _rawPricingInfo
        title
      }
      scheduleHidden
      scheduleTitle
      schedules {
        title
        scheduleItems {
          text
          time
        }
      }
      tournamentsHidden
      tournamentsTitle
      tournamentsFaq {
        question
        _rawAnswer(resolveReferences: { maxDepth: 10 })
        answer {
          children {
            text
            marks
            _type
            _key
          }
          _rawChildren
          _key
          _type
          list
          style
        }
      }
      desktopBrandImage {
        asset {
          _id
        }
      }
      mobileBrandImage {
        asset {
          _id
        }
      }
      closingCopyHidden
      _rawClosingCopy
    }
  }
`;
